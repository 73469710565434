@keyframes locker-icon-pulse1 {
  0% {
    transform: scale(1);
    opacity: 100%;
  }
  70%, 100% {
    transform: scale(1.2);
    opacity: 0%;
  }
}

@keyframes locker-icon-pulse2 {
  0%, 30% {
    transform: scale(1);
    opacity: 100%;
  }
  100% {
    transform: scale(1.2);
    opacity: 0%;
  }
}

@keyframes fade-out-bg {
  0% {
		top: -10%;
	}
	100% {
		top: 100%;
	}
}