@keyframes fade-down-in {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes fade-down-out {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}